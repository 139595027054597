import React, { useContext, useEffect, useReducer, useMemo } from 'react'

import { Box, IconButton, Tooltip } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import EditScriptsModal from './Edit-script-model';
import dayjs from 'dayjs';
import Table, { TableHook } from '../../Table';
import { ResponsiveActionBox } from '../../Table/mobileTable';

const url = '/scripts/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

const columns = (setState, permissions) => {
    return [
        { field: 'name', headerName: 'Nome', flex: 1, filterType: 'string' },
        {
            field: 'schedule_time', headerName: 'Horário de execução', flex: 1, filterType: 'daterange',
            valueGetter: ({ value }) => dayjs(value).format('HH:mm')
        },
        {
            field: 'run_at', headerName: 'Próxima execução', flex: 1, filterable: false,
            valueGetter: ({ value }) => value ? dayjs(value).format('DD/MM/YYYY HH:mm') : ''
        },
        {
            field: 'created_at', headerName: 'Data de criação', flex: 1, filterType: 'daterange',
            valueGetter: ({ value }) => dayjs(value).toDate().toLocaleString()
        },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <ResponsiveActionBox >
                        {permissions.change_scripts && <Tooltip title='Editar script'>
                            <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton>
                        </Tooltip>}
                        {permissions.delete_scripts && <Tooltip title='Excluir script'>
                            <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton>
                        </Tooltip>}
                    </ResponsiveActionBox>
                )
            }
        }
    ]
}

export const loadData = async (setState, permission, setLoader) => {
    if (!permission) {
        toast.error('Você não tem permissão para ver os scripts')
        return
    }
    const requests = [
        api.get('/permissions/')
    ]
    const [permissions] = await Promise.all(requests)
    const result = {}
    if (permissions.status === 200)
        result.permissions = permissions.data.results
    setState({ ...result })
}

const Scripts = () => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        permissions: [],
        editRow: null,
        deleteRow: null
    })

    useEffect(() => {
        loadData(setState, user.permissions.view_scripts, setLoader)
    }, [setLoader, user.permissions.view_scripts])

    const [table, reloadTable] = TableHook()

    const reloadData = () => {
        loadData(setState, user.permissions.view_scripts, setLoader)
        reloadTable()
    }

    return (
        <>
            {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o script? ${state.deleteRow.name}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
            }
            {state.editRow &&
                <EditScriptsModal
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                    permissions={state.permissions}
                />
            }
            <Box className='information-table'>
                <Box className='user-table'>
                    <Table
                        rows={state.rows}
                        columns={useMemo(()=> columns(setState, user.permissions), [user.permissions])}
                        pagination
                        density='compact'
                        slots={{
                            returnComponent: ()=> window.backtobase(),
                            addComponent: user.permissions.add_scripts ? () => setState({ editRow: {} }) : null,
                            filterComponent: true,
                            columnComponent: true
                        }}
                        toolbar
                        getURL={url}
                        updateRows={(rows) => setState({'rows': rows})}
                        innerRef={table}
                        mobile={true}
                        mobileProps={
                            {
                                title:['name']
                            }
                        }
                    />
                </Box>
            </Box>
        </>
    )
}

export default Scripts